<template>
    <div class="navbar">
        <div class="folderSide flex" style="align-items: baseline;" v-if="show_title">
            <h2 class="categoryType line-height" style="margin-right:0.3em">{{ $route.meta.title }}</h2>
<!--            <h1 class="categoryTitle" style="font-size: 25px" v-if="$route.name==='folderEdit'">{{ title }}</h1>-->
            <h1 class="categoryTitle line-height">{{ $route.meta.sub_title }}</h1>
        </div>

        <div class="nav-items">
            <div class="navbar-item">
                <div class="user-info">
                    <div class="role">
                        <router-link :to="{name: 'home'}"> Home</router-link>
                    </div>
                </div>
            </div>

            <div class="navbar-item">
                <div class="user-info">
                    <div class="role">
                        <router-link :to="{name: 'dashboard'}"> Dashboard</router-link>
                    </div>
                </div>
            </div>

            <div class="navbar-item" v-if="current_user.type === 'admin' || current_user.type === 'manager'">
                <div class="user-info">
                    <div class="role">
                        <router-link :to="{name: 'userList'}">Administration</router-link>
                    </div>
                </div>
            </div>

            <div class="navbar-item" v-if="current_user.type === 'manager'">
                <div class="user-info">
                    <div class="role">
                        <router-link :to="{name: 'paiement'}">Paiement</router-link>
                    </div>
                </div>
            </div>

            <div class="navbar-item">
                <div class="user-info">
                    <div class="role">
                        <router-link :to="{name: 'search'}">Recherche</router-link>
                    </div>
                </div>
            </div>
            <div class="navbar-item" v-if="current_user.type === 'manager'">
                <div class="user-info">
                    <div class="role">
                        <router-link :to="{name: 'statistic'}">Statistiques</router-link>
                    </div>
                </div>
            </div>

        </div>

        <div class="flex ml-auto">
            <!--<div class="navbar-item">
                <div class="ui search">
                    <div class="ui icon input">
                        <input class="prompt" type="text" placeholder="Recherche ..."
                               v-model="search_string"
                               @keyup.enter="globalSearch">
                        <i class="search icon"></i>
                    </div>
                    <div class="results"></div>
                </div>
            </div>-->

            <div class="ui dropdown" style="margin: auto">

                <div class="navbar-item">

                    <!--<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2043 33.3637C37.1011 33.3017 36.3245 32.798 35.5468 31.2743C34.1187 28.4761 33.8189 24.5343 33.8189 21.7203C33.8189 21.7081 33.8186 21.696 33.8181 21.6838C33.8026 17.9647 31.3971 14.7588 27.9549 13.3204V11.1204C27.9549 9.12411 26.2035 7.5 24.0507 7.5H23.7271C21.5743 7.5 19.8229 9.12411 19.8229 11.1204V13.3202C16.3691 14.7633 13.9589 17.9856 13.9589 21.7203C13.9589 24.5343 13.6591 28.476 12.231 31.2743C11.4534 32.7979 10.6767 33.3016 10.5735 33.3637C10.1386 33.55 9.92423 33.9804 10.0243 34.4165C10.1253 34.857 10.57 35.1607 11.0556 35.1607H18.5941C18.6362 37.8356 20.9944 40 23.8889 40C26.7834 40 29.1416 37.8356 29.1837 35.1607H36.7222C37.2078 35.1607 37.6526 34.857 37.7536 34.4165C37.8535 33.9804 37.6391 33.55 37.2043 33.3637V33.3637ZM21.8799 11.1204C21.8799 10.1759 22.7086 9.40741 23.7272 9.40741H24.0507C25.0693 9.40741 25.898 10.1759 25.898 11.1204V12.7021C25.2488 12.5779 24.5768 12.5125 23.8886 12.5125C23.2007 12.5125 22.5289 12.5778 21.88 12.7019V11.1204H21.8799ZM23.8889 38.0926C22.1286 38.0926 20.6928 36.7839 20.6511 35.1607H27.1267C27.085 36.7838 25.6492 38.0926 23.8889 38.0926V38.0926ZM28.0302 33.2532C28.03 33.2532 13.3995 33.2532 13.3995 33.2532C13.5774 32.9965 13.7585 32.7058 13.9385 32.3774C15.3169 29.861 16.0159 26.2754 16.0159 21.7203C16.0159 17.6948 19.5476 14.4199 23.8886 14.4199C28.2296 14.4199 31.7613 17.6948 31.7613 21.7235C31.7613 21.7353 31.7615 21.7469 31.7619 21.7586C31.7659 26.2954 32.4647 29.8681 33.8393 32.3774C34.0193 32.7059 34.2005 32.9965 34.3783 33.2532H28.0302Z"
                              fill="#0953AA"/>
                        <ellipse cx="34.4444" cy="17.9839" rx="5.55556" ry="5.24194" fill="#FF0000"/>
                    </svg>-->

                    <i class="outline bell big icon" style="color: #0953AA; position: relative">
                        <a class="ui red circular label" style="font-size: 8px;position: absolute;top: -3px;right: 0;">
                            {{notification_list.length}}
                        </a>
                    </i>

                </div>

                <div class="notif-menu menu">
                    <div class="notif-item item" style="padding-left: 12px !important;" v-for="notification in notification_list">
                        <div>
                            <i class="outline bell icon" style="color: #0C84AA; font-size: 18px"></i>
                        </div>
                        <div style="padding-left: 4px">
                            <span>
                                Nouvelle tâche<strong class="keyword-style"> {{notification.action.name}}</strong>
                            </span>
                            <span>
                                envoyer par: <strong class="keyword-style"> {{notification.sender}} </strong>
                            </span>

                            <span> Dossier N°:
                                <strong class="keyword-style"> {{notification.document.numero}} </strong>
                            </span>
                            <div class="notif-time">
                                {{ notification.date_creation | moment('calendar') }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="ui dropdown">
                <div class="navbar-item">
                    <div class="user-info">
                        <div class="name">
                            {{$store.state.current_user.firstname}}
                            <b>{{$store.state.current_user.lastname}}</b>
                        </div>
                        <div class="role">{{$store.state.current_user.type}}</div>
                    </div>
                    <button class="avatar-btn">
                        <img class="avatar" v-if="current_user.user_image && current_user.user_image !== ''"
                             :src="server_address + '/' + current_user.user_image" alt/>

                        <img class="avatar" src="@/assets/images/avatar.jpg" alt v-else/>
                    </button>
                </div>

                <div class="menu" style="left: 0;">
                    <div class="item" @click="logout">
                        <i class="sign-out icon"></i>
                        Se déconnecter
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            show_title: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
            }
        },
        data(){
            return {
                search_string: "",
            }
        },
        methods: {
            logout() {
                console.log("`logout`");
                this.$store.dispatchAsync('/logout', {}).then(() => {
                    this.$store.commit('SET_CURRENT_USER', {});
                    this.$router.push("/");
                    this.$eventBus.close();
                    clearInterval(this.$store.state.notification_interval)
                });
            },
            globalSearch() {
                log("globalSearch");
                this.$router.push({
                    name: "search",
                    params: { search_prop: this.search_string }
                });
            },
        },
        computed:{
            notification_list(){
                return this.$store.state.notification_list;
            },
            notification_count(){
                return this.$store.state.notification_count;
            },
            current_user(){
                return this.$store.state.current_user;
            },
            server_address(){
                return this.$store.state.SERVER_ADDRESS;
            }
        },
        mounted() {
            $('.ui.dropdown').dropdown();
        }
    };
</script>

<style scoped>
    .nav-items {
        display: inline-flex;
        margin: auto;
    }
    .notif-menu >.notif-item{
        display: flex !important;
        align-items: center!important;
        line-height: 20px!important;
        border-bottom: 1px solid #ddd !important;
        white-space: initial !important;
    }
    .keyword-style{
        color: var(--main-color-blue);
    }
    .notif-menu{
        max-height: 25em;
        overflow: auto;
        max-width: 50em!important;
        min-width: 50em!important;
        overflow-wrap: break-word;
    }
    .notif-time{
        position: relative !important;
        bottom: 0 !important;
        font-size: 12px!important;
        padding-left: 2px!important;
        padding-top: 5px!important;
        color: gray;
    }
</style>